import { useEffect, useState } from 'react'

import { classNames as cn } from '@act/lib/src/utils'
import type { TagsMetaRow } from '@act/lib/src/types'
import { CohortInterface, getMyCohorts } from '@act/lib/src/cohorts'
import { RocketIcon } from '../Icons'
import { SimplePreloader } from '~dash/src/components/Loading/SimplePreloader'
import type { HorizontalCardProps } from '~dash/src/components/Home/HorizontalCards'
import { HorizontalCards } from '~dash/src/components/Home/HorizontalCards'

// import type { TeamInterface } from '@act/lib/src/teams'

interface cardRow {
    heading: string
    headingHref?: string
    cards: HorizontalCardProps[]
}

let cohorts: CohortInterface[]
let cardRows: cardRow[]

const NoCohorts = () => (
    <div className="flex h-full min-h-screen w-full m-auto items-center justify-center bg-slate-50">
        <div className=" inset-0 m-10 bg-white shadow-frame rounded-lg">
            <div className="h-full border-4 border-gray-200 border-dashed rounded-lg flex flex-col
            text-center justify-center items-stretch p-4
            " >
                <h1 className='text-3xl font-bold underline decoration-solid decoration-brand-primary p-4'>Welcome</h1>
                <h2 className='text-2xl font-medium mx-6 mb-4'>To get started with the platform, create your first cohort!</h2>
                <a href="/cohort/new" className='group text-[36px] p-4 font-medium rounded-xl shadow-xl m-5 self-center transition hover:scale-95 animate-pulse hover:animate-none border-[5px] border-[#5eead4] hover:rotate-45 hover:border-[#f8f8ff] duration-400"'>
                    <RocketIcon className="w-14 h-14 group-hover:fill-brand-primary group-hover:stroke-2 group-hover:scale-125 duration-300" />
                </a>
            </div>
        </div>
    </div>
)

export const Home = () => {
    const [ isLoading, setLoading ] = useState( true )

    const cohortsLoaded = Array.isArray( cardRows )

    useEffect( () => {
        const fetchCohortsWithTeams = async () => {
            setLoading( true )

            if ( cardRows ) {
                console.warn( 'Rows already fetched' )
                return
            }

            cardRows = []

            const cohortCards: HorizontalCardProps[] = []
            // const teamCards: HorizontalCardProps[] = []

            const fetchedCohorts: TagsMetaRow[] = await getMyCohorts( {
                // count: 2,
            } )

            cohorts = fetchedCohorts.map( cohortRow => new CohortInterface( { cohortRow } ) )

            // // Get the teams from each cohort
            // await Promise.all( cohorts.map( async ( cohort ) => {
            //     const teams = await cohort.fetchTeams()

            //     // Add the teams to the team cards
            //     teams.forEach( ( team ) => {
            //         teamCards.push( {
            //             heading: team.name,
            //             href: team.url,
            //         } )
            //     } )

            //     return teams
            // } ) )

            const interfaces: CohortInterface[] = []

            cohorts.forEach( ( cohort ) => {
                cohortCards.push( {
                    heading: cohort.label,
                    href: cohort.url,
                } )
            } )

            // Add an extra Card for "New Project" ad the end
            cohortCards.push( {
                heading: 'New Project',
                href: '/cohort/new',
                className: 'bg-gray-200',
                iconName: 'plus-circle',
            } )

            cardRows.push( {
                heading: 'Projects',
                headingHref: '/cohort',
                cards: cohortCards,
            } )

            // cardRows.push( {
            //     heading: 'Teams',
            //     cards: teamCards,
            // } )

            // If we've loaded all cohorts
            setLoading( false )

            return interfaces
        }

        const pullFromDatabase = async () => {
            await fetchCohortsWithTeams()
        }

        pullFromDatabase()
    }, [] )

    // If cohorts are loaded and there are no cohorts, show the no cohorts component
    if ( cohortsLoaded && cohorts.length === 0 ) {
        return <NoCohorts />
    }

    const RowHeading = ( { heading }: { heading: string } ) => (
        <h2 className="font-bold text-4xl text-gray-900 ">{ heading }</h2>
    )

    return (
        <div
            className={ cn( [
                'flex items-center w-full min-h-screen',
                isLoading ? 'shimmer' : '',
            ] ) }

        >

            <main className='flex flex-col w-full'>
                { isLoading && <SimplePreloader />}
                { !isLoading && (
                    <div className="flex flex-col w-full gap-16">
                        { cardRows.map( ( row, rowIndex ) => (
                            <div
                                key={ rowIndex }
                                className="flex flex-col w-full"
                            >
                                {/* Wrap in a link if we have a headingHref */}
                                { row.headingHref
                                    ? (
                                        <a
                                            href={ row.headingHref }
                                        ><RowHeading heading={ row.heading } /></a>
                                    )
                                    : <RowHeading heading={ row.heading } />
                                }
                                <HorizontalCards
                                    items={ row.cards }
                                />
                            </div>
                        ) ) }
                    </div>
                ) }

            </main>
        </div>
    )
}
