import type { ReactNode } from 'react'

import { classNames as cn } from '@act/lib/src/utils'
import { Icon } from '~dash/src/components/Icon'

export interface HorizontalCardProps {
    [key: string]: any
    heading: string
    href: string
    className?: string
    children?: ReactNode
    cardWidth?: number
    iconName?: string
}

const Card = ( {
    heading,
    cardWidth = 640,
    href,
    className = 'bg-white',
    iconName = 'arrow-up-right',
    children,
}: HorizontalCardProps ) => (
    <li
        className="horizontal-card snap-start w-full flex-shrink-0 flex-grow-0 border-2 border-transparent px-8"
        style={ {
            maxWidth: `${ cardWidth }px`,
            flexBasis: `${ cardWidth }px`,
        } }
    >
        <a href={ href }>
            <div
                className={ cn( [
                    'inset-0 bg-white border-4 border-gray-200 shadow-frame rounded-lg',
                    className,
                ] ) }
                style={ {
                    minHeight: `${ cardWidth * 0.4 }px`,
                } }
            >
                <div className={ [
                    'h-full p-6 gap-4',
                    // Flexbox
                    'flex flex-col justify-center items-stretch',
                ].join( ' ' ) } >
                    <div className='flex justify-between gap-2'>
                        <h3 className='text-3xl font-bold underline decoration-solid decoration-brand-primary'>{ heading }</h3>
                        <Icon
                            name={ iconName }
                            className="w-6 h-6"
                        />
                    </div>
                    { children }
                </div>
            </div>
        </a>
    </li>
)

export interface HorizontalCardsProps {
    [key: string]: any
    items: HorizontalCardProps[]
}

export const HorizontalCards = ( props: HorizontalCardsProps ) => {
    const cardWidth = 450

    return (
        <div
            className="relative w-full max-w-screen"
        >
            <ul
                className="row-contents flex snap-x snap-mandatory overflow-x-auto whitespace-no-wrap py-12"
            >
                { props.items.map( ( item, index ) => (
                    <Card
                        key={ index }
                        { ...item }
                        cardWidth={ cardWidth }
                    />
                ) ) }
            </ul>
        </div>
    )
}

export default HorizontalCards
